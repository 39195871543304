* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    background-color: #27272a;
    color: #C5C6D0;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: #EFF0FA;
    margin-bottom: 0.5rem;
  }

  .App {
    height: 100vh;
  }
  
  header {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  form {
    margin-top: 2rem;
    width: 100%;
    max-width: 450px;
    /* box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4); */
  }
  
  input {
    display: block;
    width: 100%;
    border-radius: 8px;
    border: 2px solid transparent;
    height: 48px;
    box-sizing: border-box;
    padding: 12px 16px;
    background: #191B23;
    color: inherit;
    font-family: inherit;
  }
  
  input::placeholder {
    color: #aaa;
  }
  
  .input-container {
    margin-bottom: 1rem;
    display: flex;
    align-items: baseline;
    gap: 5px;
  }

  .btn {
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 8px 16px;
    font-weight: 500;
    height: 48px;
    box-sizing: border-box;
    font-size: 15px;
    color: white;
    cursor: pointer;
  }
  
  .btn-primary {
    background-color: #2572ED;
  }
  
  
  .btn-danger {
    padding: 8px;
    background-color:#C74E5B;
  }
  
  .conference-section {
    padding: 5px 20px 10px;
    max-width: 1000px;
    height: calc(100vh - 98px);
    margin: 0 auto;
  }
  
  .conference-section h2 {
    text-align: center;
    font-size: 32px;
    padding-bottom: 10px;
    border-bottom: 1px solid #546e7a;
  }
  
  .peers-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1000px;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .peer-video {
    height: 250px;
    width: 450px;
    border-radius: 0.3rem;
    object-fit: cover;
    margin: 10px;
    border: 1px solid #191B23;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.6);
  }

  .video-cover {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #191B23;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .local.peer-video {
    transform: scaleX(-1);
  }
  
  .peer-name {
    font-size: 14px;
    text-align: center;
  }
  
  .peer-container {
    position: relative;
  }
  
  .control-bar {
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 15px;
    justify-content: center;
    z-index: 10;
  }
  
  .control-bar > *:not(:first-child) {
    margin-left: 8px;
  }
  
  .btn-control {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: 1px solid #37474f;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    text-align: center;
    background: none;
    color: white;
    cursor: pointer;
  }
  
  .hide {
    display: none;
  }

  .separator {
    font-size: 12px;
  }
  
  .home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
  }

  .center {
    align-items: center;
  }

  .highlight {
    background: #2E3038;
  }